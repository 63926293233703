import React, { Fragment, useState, useEffect } from "react";
import random from "lodash/random";
import Typing from "react-typing-animation";

import { useMediaQuery } from "../../hooks";
import { resume } from "../../constants";
import Link from "../../components/Link";

export default function AnimatedSubText() {
  const largeScreen = useMediaQuery("lg");
  const increaseSubtextHeight = useMediaQuery("xs", "sm", "lg");
  const [height, setHeight] = useState(
    increaseSubtextHeight ? "4rem" : undefined
  );
  const [descriptorsSample, setDescriptorsSample] = useState<string[]>([]);

  useEffect(() => {
    setDescriptorsSample(getDescriptions());
  }, []);

  if (!descriptorsSample.length) return null;

  return (
    <>
      <div
        className="AnimatedSubText"
        style={{
          fontSize: largeScreen ? "1.5rem" : "1.25rem",
          height
        }}
      >
        <span style={{ lineHeight: "110%" }}>
          <Typing
            cursorClassName="cursor"
            onFinishedTyping={() => setHeight(undefined)}
          >
            <span> </span>
            <Typing.Delay ms={1000} />
            <span>Full-stack application developer and </span>
            {["system architect. ", ...descriptorsSample].map(
              (descriptor, i) => {
                descriptor = `${descriptor} `;
                return (
                  <Fragment key={i}>
                    <span>{descriptor}</span>
                    <Typing.Delay ms={1800} />
                    <Typing.Backspace count={descriptor.length} />
                    <Typing.Delay ms={800} />
                  </Fragment>
                );
              }
            )}
            <Link href={resume}>asset for hire.</Link>
            <span> </span>
            <Typing.Delay ms={3000} />
          </Typing>
        </span>
      </div>

      <style>{`
        .AnimatedSubText .cursor {
          color: #fff;
        }
      `}</style>
    </>
  );
}

const descriptors = [
  "pragmatist.",
  "wannabe designer 💅.",
  "user advocate.",
  "collaborator.",
  "ping pong enthusiast 🏓.",
  "outdoorsman 🏔."
];

function getDescriptions() {
  const descriptorsCopy = [...descriptors];

  const takeOneDescriptor = () => {
    const randIndex = random(0, descriptorsCopy.length - 1);

    // Remove random element from the array;
    return descriptorsCopy.splice(randIndex, randIndex || 1)[0];
  };

  return [takeOneDescriptor(), takeOneDescriptor()];
}
