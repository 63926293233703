import React from "react";

import bgImage from "./assets/rappel.jpeg";
import bgImageMobile from "./assets/rappel-mobile.jpeg";

import { useMediaQuery } from "../../hooks";

const FullScreenBackgroundImage: React.FC = ({ children }) => {
  const smallScreen = useMediaQuery("xs", "sm");

  return (
    <div
      className="vw-100"
      style={{
        height: "100vh",
        backgroundImage: `url(${smallScreen ? bgImageMobile : bgImage})`,
        backgroundRepeat: "none",
        backgroundSize: "cover"
      }}
    >
      <div
        className="h-100 w-100"
        style={{
          background:
            "linear-gradient(0deg, rgba(0,0,0,0) 31%, rgba(0,0,0,1) 100%)",
          overflowY: "scroll"
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default FullScreenBackgroundImage;
