import React from "react";
import { AnchorHTMLAttributes } from "react";

export type ILinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;
export default function Link(props: ILinkProps) {
  const { children, href, style, ...rest } = props;
  return (
    <a
      href={href}
      style={{ color: "white", ...style }}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </a>
  );
}
