import React, { CSSProperties } from "react";

import { useMediaQuery } from "../hooks";
import FullScreenBackgroundImage from "./FullScreenBackgroundImage";
import AnimatedSubText from "./AnimatedSubtext";
import { email, resume } from "../constants";
import Link, { ILinkProps } from "../components/Link";

import "./utils.scss";

export default function App() {
  const largeScreen = useMediaQuery("lg", "xl");
  const smallScreen = useMediaQuery("xs", "sm");

  return (
    <>
      <FullScreenBackgroundImage>
        <div
          id="copy-container"
          style={{
            minHeight: "103vh",
            padding: largeScreen ? "4rem" : "1rem",
            paddingBottom: "4rem"
          }}
        >
          <header style={{ marginBottom: ".5rem" }}>
            <h1 style={{ lineHeight: "88%" }}>Brandon R. Howard</h1>
          </header>

          <AnimatedSubText />

          <p
            style={{
              fontSize: smallScreen ? "0.9rem" : "0.8rem",
              maxWidth: "80vw"
            }}
          >
            I'm passionate about building tools that automate mundane tasks and
            hide complexity. I accel at making dynamic user interfaces that are
            easy and enjoyable to use. I'm detail-oriented and love solving
            problems cleanly and efficiently. If you're curious and want to know
            more about me I'd love to hear from you.
          </p>

          <Links style={{ marginTop: "3rem" }} />
        </div>
      </FullScreenBackgroundImage>
    </>
  );
}

function Links({ style }: { style: CSSProperties }) {
  const stackLinks = useMediaQuery("xs", "sm");
  const marginRight = ".7rem";
  const Divider = () =>
    stackLinks ? null : <div style={{ marginRight }}>|</div>;

  const StyledLink = (props: Omit<ILinkProps, "style">) => (
    <Link style={{ marginBottom: "1.5rem", marginRight }} {...props} />
  );

  return (
    <div
      style={{
        ...style,
        display: "flex",
        fontSize: ".7rem",
        flexDirection: stackLinks ? "column" : "row"
      }}
    >
      <StyledLink href={`mailto:${email}`}>Contact</StyledLink>

      <Divider />

      <StyledLink href={resume}>Resume</StyledLink>

      <Divider />

      <StyledLink href="https://github.com/brayhoward">Github</StyledLink>

      <Divider />

      <StyledLink href={"https://www.linkedin.com/in/brandonrhoward"}>
        LinkedIn
      </StyledLink>
    </div>
  );
}
