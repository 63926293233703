import { useWindowSize } from "./useWindowSize";
type TQuery = "xs" | "sm" | "md" | "lg" | "xl";
export function useMediaQuery(...queries: TQuery[]) {
  const { width } = useWindowSize();
  const screenSize = getScreenSize(width);

  // If any of the query match the screenSize return
  return queries.some(query => query === screenSize);
}

function getScreenSize(width: number) {
  if (width >= 1175) {
    return "xl";
  }
  if (width >= 955) {
    return "lg";
  }
  if (width > 600) {
    return "md";
  }

  if (width > 365) {
    return "sm";
  }

  return "xs";
}
